import { useEffect } from 'react'
import { connect } from 'react-redux'

const QrCodePage = ({ declarePageAsNotLanding }) => {
  declarePageAsNotLanding()

  useEffect(() => {
    window.location.href = '/ticker'
  }, [])

  return null
}

const mapStateToProps = () => {
  return {}
}

const mapDispatchToProps = dispatch => {
  return {
    declarePageAsNotLanding: () => dispatch({ type: `SET_LANDING_PAGE_FALSE` }),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(QrCodePage)
